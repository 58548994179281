var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-img", {
        attrs: {
          src: require("@/assets/images/icons/logo.svg"),
          "max-width": "100",
        },
      }),
      _c("div", { staticClass: "top-nav-bar-logo" }, [
        _vm._v(_vm._s(_vm.$t("TopNavBarLogoText"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }