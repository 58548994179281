var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.showSystemBar
        ? _c(
            "v-system-bar",
            {
              attrs: {
                id: "banner-system-bar",
                height: _vm.SystemBarHeight,
                app: "",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("TopBannerText")) + " ")]
          )
        : _vm._e(),
      _c("top-information-banner", {
        style: {
          marginTop: _vm.showSystemBar ? _vm.SystemBarHeight + "px" : "",
        },
      }),
      _c("top-nav-bar", { attrs: { topBannerHeight: _vm.SystemBarHeight } }),
      _c(
        "v-content",
        {
          staticClass: "app-content",
          staticStyle: { "min-height": "100vh" },
          attrs: { id: "vcontent-twilio" },
        },
        [_c("transition-page", [_c("router-view")], 1)],
        1
      ),
      _c("footer-section"),
      _c("vue-snotify"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }