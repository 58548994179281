var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "top-banner" } }, [
    _vm.$vuetify.breakpoint.lgAndUp
      ? _c("div", { staticClass: "d-flex align-self-center mb-0 px-3" }, [
          _c("ul", { staticClass: "list-unstyled topBarBanner" }, [
            _c("li", [
              _c(
                "span",
                { staticClass: "warranty-icon" },
                [
                  _c("v-img", {
                    attrs: {
                      width: "16",
                      src: require("../assets/icons/warranty/warranty-icon.svg"),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("MonthsWarranty"))),
            ]),
            _vm._m(0),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "d-flex d-lg-none justify-content-center align-items-center mb-1 px-1 pt-3",
        staticStyle: { height: "auto", "letter-spacing": "0.4px" },
      },
      [
        _c("ul", { staticClass: "list-unstyled topBarBanner" }, [
          _c("li", [
            _c(
              "span",
              { staticClass: "warranty-icon" },
              [
                _c("v-img", {
                  attrs: {
                    width: "16",
                    src: require("../assets/icons/warranty/warranty-icon.svg"),
                  },
                }),
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.$t("MonthsWarranty"))),
          ]),
        ]),
        _c(
          "v-btn",
          {
            staticClass:
              "white--text text-none font-weight-normal mx-auto letterspace",
            attrs: { color: "#12945F", to: { name: "AutoEstimate" } },
          },
          [_vm._v(" " + _vm._s(_vm.$t("GetOnlineEstimate")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "callinfo" }, [
        _c("i", {
          staticClass: "mdi mdi-phone d-block d-sm-none d-md-none d-lg-block",
        }),
      ]),
      _c(
        "a",
        {
          staticClass: "text-decoration-underline white--text",
          staticStyle: { "margin-left": "-2px" },
          attrs: { href: "tel:9729729197" },
        },
        [_vm._v("(972) 972-9197")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }