var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { id: "footer-container" } },
    [
      _c(
        "v-row",
        { staticClass: "col-12 mt-16" },
        [
          _c(
            "v-col",
            {
              staticClass: "col-12 col-md-3 col-sm-5 pt-4",
              attrs: { id: "ucar-mobile-col" },
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/", "aria-label": "Home" } },
                [
                  _c("v-img", {
                    staticStyle: { "margin-left": "1px" },
                    attrs: {
                      src: require("@/assets/images/icons/ucarmobile logo-white.svg"),
                      width: "192",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "col-12 col-md-3 col-sm-5",
              attrs: { id: "contact-us-col" },
            },
            [
              _c("div", [
                _c("h3", { staticClass: "my-0" }, [
                  _vm._v(_vm._s(_vm.$t("ContactUs"))),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "mt-2",
                    attrs: { href: "mailto:tonyb@ucarmobile.com" },
                  },
                  [_vm._v(" tonyb@ucarmobile.com ")]
                ),
              ]),
              _c("div", { attrs: { id: "hours-of-operation" } }, [
                _c("h3", { staticClass: "my-0" }, [
                  _vm._v(_vm._s(_vm.$t("Footer.HoursOfOperation"))),
                ]),
                _c("ul", [
                  _c("li", [_vm._v(_vm._s(_vm.$t("Footer.MondaySaturday")))]),
                  _c("li", [_vm._v(_vm._s(_vm.$t("Footer.ClosedSunday")))]),
                ]),
              ]),
            ]
          ),
          _c("v-col", { staticClass: "col-12 col-md-2 col-sm-5 footer-col" }, [
            _c("div", [
              _c("h3", { staticClass: "my-0 font-weight-medium" }, [
                _vm._v(" " + _vm._s(_vm.$t("Footer.Company"))),
              ]),
              _c("ul", { staticClass: "mt-2" }, [
                _c(
                  "li",
                  { staticClass: "text-capitalize" },
                  [
                    _c("router-link", { attrs: { to: { name: "AboutUs" } } }, [
                      _vm._v(_vm._s(_vm.$t("AboutUs"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "text-capitalize" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "MobileServices" } } },
                      [_vm._v(_vm._s(_vm.$t("Footer.WhyMobileService")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "text-capitalize" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "HowItWorks" } } },
                      [_vm._v(_vm._s(_vm.$t("HowItWorks")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "text-uppercase" },
                  [
                    _c("router-link", { attrs: { to: { name: "Faq" } } }, [
                      _vm._v(_vm._s(_vm.$t("Feq"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "col-12 col-md-2 col-sm-5 footer-col" }, [
            _c("h3", { staticClass: "my-0" }, [
              _vm._v(" " + _vm._s(_vm.$t("Footer.SocialMedia"))),
            ]),
            _c("ul", { staticClass: "mt-2" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "preconnect canonical",
                      href: "https://twitter.com/Ucarmobile_",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Twitter")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "preconnect canonical",
                      href: "https://www.instagram.com/ucarmobile.mechanic/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Instagram")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "preconnect canonical",
                      href: "https://www.facebook.com/UcarMobile-103345378478293",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Facebook")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "preconnect canonical",
                      href: "https://www.tiktok.com/@ucarmobile.mechanic",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Tiktok")]
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "col-12 col-md-2 col-sm-5 footer-col" }, [
            _c("h3", { staticClass: "my-0" }, [
              _vm._v(_vm._s(_vm.$t("Footer.DownloadOurApps"))),
            ]),
            _c("ul", { staticClass: "mt-2" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "canonical",
                      href: "https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232?utm_source=ho[…]&utm_campaign=ios_homepage_install&utm_id=iOS&platform=iphone",
                      target: "_blank",
                      "aria-label": "UcarMobile android store link",
                    },
                  },
                  [
                    _c("v-img", {
                      staticClass: "mb-3",
                      attrs: {
                        src: require("../assets/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"),
                        width: _vm.$vuetify.breakpoint.smAndUp
                          ? "100%"
                          : "200px",
                        "max-width": "200px",
                        contain: "",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      rel: "canonical",
                      href: "https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect&utm_source=Website&utm_campaign=Android%20Installs%20New%20Website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                      target: "_blank",
                    },
                  },
                  [
                    _c("v-img", {
                      staticClass: "mb-3",
                      attrs: {
                        src: require("../assets/icons/Google_Play_Store_badge_EN.svg"),
                        alt: "Get it on Google Play",
                        width: _vm.$vuetify.breakpoint.smAndUp
                          ? "100%"
                          : "200px",
                        "max-width": "200px",
                        contain: "",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "v-col",
            { staticClass: "col-12 pl-md-15" },
            [_c("v-divider", { attrs: { color: "#fff" } })],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12" },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-md-space-between",
                  attrs: { id: "footer-last-details" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "col-12 col-md-3 order-3 order-md-1 mt-md-2 pl-md-0",
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.copyright()))])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "col-12 col-md-3 d-flex justify-md-end order-2 order-md-3 mt-md-2 pr-md-0",
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "footer-link",
                          attrs: { to: { name: "Terms" } },
                        },
                        [_vm._v(_vm._s(_vm.$t("TermAndConditions.Title")))]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "footer-link ml-5",
                          attrs: { to: { name: "Privacy" } },
                        },
                        [_vm._v(_vm._s(_vm.$t("Footer.PrivacyPolicy")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }