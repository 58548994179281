<template>
  <v-footer id="footer-container">
    <v-row class="col-12 mt-16">
      <v-col id="ucar-mobile-col" class="col-12 col-md-3 col-sm-5 pt-4">
        <router-link to="/" aria-label="Home">
        <v-img
          style="margin-left: 1px;"
          :src="require(`@/assets/images/icons/ucarmobile logo-white.svg`)"
          width="192"/>
        </router-link>
      </v-col>
      <v-col id="contact-us-col" class="col-12 col-md-3 col-sm-5">
        <div>
          <h3 class="my-0">{{ $t('ContactUs') }}</h3>
          <!-- 'al@ucarmobile.com' replaced with 'tonyb@ucarmobile.com' -->
          <a class="mt-2"
            href="mailto:tonyb@ucarmobile.com" >
            tonyb@ucarmobile.com
          </a>
        </div>
        <div id="hours-of-operation">
          <h3 class="my-0">{{ $t('Footer.HoursOfOperation') }}</h3>
          <ul>
            <li>{{ $t('Footer.MondaySaturday') }}</li>
            <li>{{ $t('Footer.ClosedSunday') }}</li>
          </ul>
        </div>
      </v-col>
      <v-col class="col-12 col-md-2 col-sm-5 footer-col">
        <div>
          <h3 class="my-0 font-weight-medium"> {{$t('Footer.Company') }}</h3>
          <ul class="mt-2">
            <li class="text-capitalize"><router-link :to="{name:'AboutUs'}">{{$t('AboutUs')}}</router-link></li>
            <li class="text-capitalize"><router-link :to="{name:'MobileServices'}">{{ $t('Footer.WhyMobileService') }}</router-link></li>
            <li class="text-capitalize"><router-link :to="{name:'HowItWorks'}">{{ $t('HowItWorks') }}</router-link></li>
            <li class="text-uppercase"><router-link :to="{name:'Faq'}">{{$t('Feq')}}</router-link></li>
          </ul>
        </div>
      </v-col>
      <v-col class="col-12 col-md-2 col-sm-5 footer-col">
        <h3 class="my-0"> {{$t('Footer.SocialMedia') }}</h3>
        <ul class="mt-2">
          <li><a rel="preconnect canonical" href="https://twitter.com/Ucarmobile_" target="_blank">Twitter</a></li>
          <li><a rel="preconnect canonical" href="https://www.instagram.com/ucarmobile.mechanic/" target="_blank">Instagram</a></li>
          <li><a rel="preconnect canonical" href="https://www.facebook.com/UcarMobile-103345378478293" target="_blank">Facebook</a></li>
          <li><a rel="preconnect canonical" href="https://www.tiktok.com/@ucarmobile.mechanic" target="_blank">Tiktok</a></li>
        </ul>
      </v-col>
      <v-col class="col-12 col-md-2 col-sm-5 footer-col">
        <h3 class="my-0">{{$t('Footer.DownloadOurApps') }}</h3>
        <ul class="mt-2">
          <li>
            <a rel="canonical" href="https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232?utm_source=ho[…]&utm_campaign=ios_homepage_install&utm_id=iOS&platform=iphone" target="_blank" aria-label="UcarMobile android store link">
              <v-img
                :src="require(`../assets/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`)"
                :width="$vuetify.breakpoint.smAndUp ? '100%' : '200px'"
                max-width="200px"
                contain class="mb-3"></v-img>
            </a>
          </li>
          <li>
            <a rel="canonical" href="https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect&utm_source=Website&utm_campaign=Android%20Installs%20New%20Website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank">
              <v-img
                :src="require(`../assets/icons/Google_Play_Store_badge_EN.svg`)"
                alt="Get it on Google Play"
                :width="$vuetify.breakpoint.smAndUp ? '100%' : '200px'"
                max-width="200px"
                contain
                class="mb-3"/>
            </a>
          </li>
        </ul>
      </v-col>
      <v-col class="col-12 pl-md-15">
        <v-divider color="#fff"></v-divider>
      </v-col>
      <v-col class="col-12">
        <v-row id="footer-last-details" class="d-flex justify-md-space-between">
          <v-col class="col-12 col-md-3 order-3 order-md-1 mt-md-2 pl-md-0">
            <span>{{ copyright() }}</span>
          </v-col>
          <v-col class="col-12 col-md-3 d-flex justify-md-end order-2 order-md-3 mt-md-2 pr-md-0">
              <router-link :to="{name:'Terms'}" class="footer-link">{{ $t('TermAndConditions.Title') }}</router-link>
              <router-link :to="{name:'Privacy'}" class="footer-link ml-5">{{ $t('Footer.PrivacyPolicy') }}</router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    copyright() {
      return `© ${new Date().getFullYear()} Security Ride, LLC. All Rights Reserved.`
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/styles/sass/components/footer";
</style>
