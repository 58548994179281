var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hide-nav-bar" },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "z-index-6 d-flex p-relative",
          staticStyle: { "flex-direction": "column" },
          style: { marginTop: _vm.topBannerHeight + 48 + "px" },
          attrs: { app: "" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex w-100 justify-space-between",
              attrs: { id: "menu-container" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex d-lg-none visible-xs w-100 justify-space-between",
                  attrs: { id: "logo-and-app-nav" },
                },
                [
                  _c("v-img", {
                    staticClass: "hidden-lg-and-up align-self-center pa-0",
                    attrs: {
                      contain: "",
                      "max-width": "30",
                      "max-height": "30",
                      src: require("@/assets/icons/hamburger-menu-ic.svg"),
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      },
                    },
                  }),
                  _c(
                    "router-link",
                    {
                      staticClass: "align-self-center pl-1",
                      attrs: { to: "/", "aria-label": "Home" },
                    },
                    [_c("TopNavBarLogo")],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex d-lg-none visible-xs align-center" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "px-0",
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "align-self-center pa-0 pl-1 pr-1",
                              attrs: { id: "callus" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-none font-weight-normal text-white",
                                  staticStyle: {
                                    color: "#fff !important",
                                    padding: "0 12px",
                                  },
                                  attrs: {
                                    color: "#00663d",
                                    href: "tel:9729729197",
                                    "aria-label": "Call Us",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { "margin-right": "3px" },
                                      attrs: { left: "" },
                                    },
                                    [_vm._v(" mdi-phone ")]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.$t("CallUs")) + " "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "align-self-center pa-0" },
                            [
                              !this.$store.state.authenticate.UserLogged
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none font-weight-bold",
                                      attrs: {
                                        small:
                                          this.$vuetify.breakpoint.width < 390,
                                        text: "",
                                        color: "#12945F",
                                        outlined: "",
                                      },
                                      on: { click: _vm.visibleLogin },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Login")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              this.$store.state.authenticate.UserLogged
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "menu-dropdown mr-1",
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getUserName
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-chevron-down"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2289917522
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _vm._l(
                                            _vm.itemsAuthorized.filter(
                                              function (x) {
                                                return x.visible
                                              }
                                            ),
                                            function (item, i) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "list-item-container",
                                                  attrs: {
                                                    to: { name: item.path },
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass: "list-item",
                                                    },
                                                    [
                                                      _c(
                                                        "v-badge",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.title ===
                                                                  "MyMessages" &&
                                                                +_vm.$store
                                                                  .state.message
                                                                  .unreadMessageCount >
                                                                  0,
                                                              expression:
                                                                "item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0",
                                                            },
                                                          ],
                                                          attrs: {
                                                            color: "blue",
                                                            content:
                                                              +_vm.$store.state
                                                                .message
                                                                .unreadMessageCount,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  item.title
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.title !==
                                                                  "MyMessages" ||
                                                                (item.title ===
                                                                  "MyMessages" &&
                                                                  +_vm.$store
                                                                    .state
                                                                    .message
                                                                    .unreadMessageCount ===
                                                                    0),
                                                              expression:
                                                                "item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(item.title)
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-container",
                                              on: { click: _vm.logout },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "list-item" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("Logout")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-none d-lg-flex",
                  attrs: { id: "logo-and-app-nav-1" },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "align-self-center text-decoration-none",
                      attrs: { to: "/", "aria-label": "Home" },
                    },
                    [_c("TopNavBarLogo")],
                    1
                  ),
                  _c("v-app-bar-nav-icon", {
                    staticClass: "hidden-md-and-up",
                    staticStyle: { border: "0" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-none d-lg-flex align-center",
                  attrs: { id: "menu-items" },
                },
                [
                  _vm._l(_vm.items.slice(0, 1), function (item) {
                    return _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showItemInMenu(item),
                            expression: "showItemInMenu(item)",
                          },
                        ],
                        key: item.title,
                        staticClass: "hidden-sm-and-down item-menu",
                        attrs: {
                          to: { name: item.path },
                          text: "",
                          small: "",
                          exact: "",
                        },
                      },
                      [
                        _c(
                          "v-badge",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.title === "MyMessages" &&
                                  _vm.$store.state.message.unreadMessageCount >
                                    0,
                                expression:
                                  "item.title === 'MyMessages' && $store.state.message.unreadMessageCount > 0",
                              },
                            ],
                            attrs: {
                              color: "blue",
                              content:
                                _vm.$store.state.message.unreadMessageCount,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.title !== "MyMessages" ||
                                  (item.title === "MyMessages" &&
                                    _vm.$store.state.message
                                      .unreadMessageCount === "0"),
                                expression:
                                  "item.title !== 'MyMessages' || (item.title === 'MyMessages' && $store.state.message.unreadMessageCount === '0') ",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t(item.title)))]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4",
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(" " + _vm._s(_vm.$t("Service")) + " "),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-sm-and-down" },
                        _vm._l(_vm.servicesItems, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSubitemsInMenu(item),
                                  expression: "showSubitemsInMenu(item)",
                                },
                              ],
                              key: i,
                              staticClass: "list-item-container",
                              attrs: { to: { name: item.path }, small: "" },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item" },
                                [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4",
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Footer.Company")) + " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-sm-and-down" },
                        _vm._l(_vm.companyItems, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSubitemsInMenu(item),
                                  expression: "showSubitemsInMenu(item)",
                                },
                              ],
                              key: i,
                              staticClass: "list-item-container",
                              attrs: { to: { name: item.path }, small: "" },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item" },
                                [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4",
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("TopBarResources")) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-sm-and-down" },
                        _vm._l(_vm.resourcesItems, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSubitemsInMenu(item),
                                  expression: "showSubitemsInMenu(item)",
                                },
                              ],
                              key: i,
                              staticClass: "list-item-container",
                              attrs: { to: { name: item.path }, small: "" },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item" },
                                [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4",
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("TopBarSupport")) + " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-sm-and-down" },
                        _vm._l(_vm.supportItems, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSubitemsInMenu(item),
                                  expression: "showSubitemsInMenu(item)",
                                },
                              ],
                              key: i,
                              staticClass: "list-item-container",
                              attrs: { to: { name: item.path }, small: "" },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item" },
                                [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.items.slice(1, 15), function (item) {
                    return _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showItemInMenu(item),
                            expression: "showItemInMenu(item)",
                          },
                        ],
                        key: item.title,
                        staticClass: "hidden-sm-and-down item-menu",
                        attrs: {
                          to: { name: item.path },
                          text: "",
                          small: "",
                          exact: "",
                        },
                      },
                      [
                        _c(
                          "v-badge",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.title === "MyMessages" &&
                                  +_vm.$store.state.message.unreadMessageCount >
                                    0,
                                expression:
                                  "item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0",
                              },
                            ],
                            attrs: {
                              color: "blue",
                              content:
                                +_vm.$store.state.message.unreadMessageCount,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.title !== "MyMessages" ||
                                  (item.title === "MyMessages" &&
                                    +_vm.$store.state.message
                                      .unreadMessageCount === 0),
                                expression:
                                  "item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t(item.title)))]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2 my-2 text-capitalize",
                      attrs: {
                        color: "#00663D",
                        to: { name: "AutoEstimate" },
                        outlined: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GetAnEstimate")) + " ")]
                  ),
                ],
                2
              ),
              _c(
                "div",
                { attrs: { id: "login-section" } },
                [
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "menu-dropdown hidden-md-and-down mr-4",
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("SwitchToLanguage")) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-md-and-down" },
                        [
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-container",
                                  attrs: { small: "" },
                                  on: { click: _vm.changeLocale },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "list-item" },
                                    [_vm._v(" English ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-container",
                                  attrs: { small: "" },
                                  on: { click: _vm.changeLocale },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "list-item" },
                                    [_vm._v(" Español ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !this.$store.state.authenticate.UserLogged
                    ? _c(
                        "span",
                        {
                          staticClass: "hidden-md-and-down",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.visibleLogin },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Login")) + " ")]
                      )
                    : _vm._e(),
                  !this.$store.state.authenticate.UserLogged
                    ? _c(
                        "v-btn",
                        {
                          staticClass:
                            "hidden-md-and-down white--text text-none font-weight-bold",
                          attrs: { elevation: "0", color: "#12945F" },
                          on: {
                            click: function ($event) {
                              return _vm.openSignUp()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("GetStarted")) + " ")]
                      )
                    : _vm._e(),
                  this.$store.state.authenticate.UserLogged
                    ? _c(
                        "v-menu",
                        {
                          staticClass: "hidden-md-and-down",
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "menu-dropdown hidden-md-and-down mr-1",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.getUserName) + " "
                                        ),
                                        _c("v-icon", [
                                          _vm._v("mdi-chevron-down"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4137517925
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm._l(
                                _vm.itemsAuthorized.filter(function (x) {
                                  return x.visible
                                }),
                                function (item, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      staticClass: "list-item-container",
                                      attrs: {
                                        to: { name: item.path },
                                        small: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "list-item" },
                                        [
                                          _c(
                                            "v-badge",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.title ===
                                                      "MyMessages" &&
                                                    +_vm.$store.state.message
                                                      .unreadMessageCount > 0,
                                                  expression:
                                                    "item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0",
                                                },
                                              ],
                                              attrs: {
                                                color: "blue",
                                                content:
                                                  +_vm.$store.state.message
                                                    .unreadMessageCount,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t(item.title)) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.title !==
                                                      "MyMessages" ||
                                                    (item.title ===
                                                      "MyMessages" &&
                                                      +_vm.$store.state.message
                                                        .unreadMessageCount ===
                                                        0),
                                                  expression:
                                                    "item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ",
                                                },
                                              ],
                                            },
                                            [_vm._v(_vm._s(_vm.$t(item.title)))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass:
                                    "hidden-sm-and-down list-item-container",
                                  on: { click: _vm.logout },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "list-item" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Logout")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-badge", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: +_vm.$store.state.message.unreadMessageCount > 0,
                        expression:
                          "+$store.state.message.unreadMessageCount > 0",
                      },
                    ],
                    class: ["p-relative", "mr-3"],
                    style: {
                      top: _vm.$vuetify.breakpoint.mdAndDown ? "16px" : "0px",
                    },
                    attrs: {
                      color: "blue",
                      content: +_vm.$store.state.message.unreadMessageCount,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "15" },
          attrs: { color: "white", app: "", temporary: "", left: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-menu",
                    {
                      staticStyle: { border: "0 !important" },
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass:
                                      "text-none ml-0 menu-item-mobile-design",
                                    attrs: {
                                      small: "",
                                      elevation: "0",
                                      outlined: "",
                                      color: "#12945f",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("SwitchToLanguage")) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "hidden-lg-and-up" },
                        [
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-container",
                                  attrs: { small: "" },
                                  on: { click: _vm.changeLocale },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "list-item" },
                                    [_vm._v(" English ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-container",
                                  attrs: { small: "" },
                                  on: { click: _vm.changeLocale },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "list-item" },
                                    [_vm._v(" Español ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "", height: "80%" } },
            [
              _c(
                "v-list-item-content",
                { staticStyle: { padding: "0px 0px" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: _vm.items[0].path } } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "menu-item-mobile-design" },
                        [
                          _c(
                            "span",
                            { staticClass: "hidden-lg-and-up text-capitalize" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(_vm.items[0].title)) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "menu-item-mobile-design" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "hidden-lg-and-up text-capitalize",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Service")) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.servicesItems, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubitemsInMenu(item),
                          expression: "showSubitemsInMenu(item)",
                        },
                      ],
                      key: i,
                      staticClass: "list-item-container",
                      attrs: { to: { name: item.path }, small: "" },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item" },
                            [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "menu-item-mobile-design" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "hidden-lg-and-up text-capitalize",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Footer.Company")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.companyItems, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubitemsInMenu(item),
                          expression: "showSubitemsInMenu(item)",
                        },
                      ],
                      key: i,
                      staticClass: "list-item-container",
                      attrs: { to: { name: item.path }, small: "" },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item" },
                            [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "menu-item-mobile-design" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "hidden-lg-and-up text-capitalize",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("TopBarResources")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.resourcesItems, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubitemsInMenu(item),
                          expression: "showSubitemsInMenu(item)",
                        },
                      ],
                      key: i,
                      staticClass: "list-item-container",
                      attrs: { to: { name: item.path }, small: "" },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item" },
                            [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "menu-item-mobile-design" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "hidden-lg-and-up text-capitalize",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("TopBarSupport")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.supportItems, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubitemsInMenu(item),
                          expression: "showSubitemsInMenu(item)",
                        },
                      ],
                      key: i,
                      staticClass: "list-item-container",
                      attrs: { to: { name: item.path }, small: "" },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item" },
                            [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              void 0,
            ],
            2
          ),
          _c(
            "v-container",
            { staticClass: "text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "white--text text-none font-weight-bold justify-center",
                  staticStyle: { padding: "0 5px" },
                  attrs: {
                    elevation: "0",
                    color: "#12945F",
                    href: "tel:9729729197",
                  },
                },
                [
                  _c("v-icon", [_vm._v("call")]),
                  _vm._v(" " + _vm._s(_vm.$t("CallUs")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("login-form"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }