import Vue from 'vue';

function currency(value, decimals = 2) {
  return (value !== null || Number.isNaN(Number(value)))
    ? `$${parseFloat(value).toFixed(decimals).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`
    : '$0.00';
}

function phoneMask(value) {
  const prefix = `(${value.substr(0, 3)})`;
  return prefix + value.substr(3, value.length - 1);
}

Vue.filter('currency', currency);
Vue.filter('phoneMask', phoneMask);
