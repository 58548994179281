var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTermsConditionsVisible
    ? _c(
        "div",
        [
          _c("v-checkbox", {
            staticClass: "mt-0 checkbox-color",
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-thin",
                          staticStyle: { "font-size": "1rem" },
                        },
                        [
                          _vm._v(" I have read and agree to the "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openCloseTCDialog(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("TermsConditions")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3838294349
            ),
            model: {
              value: _vm.termsApproved,
              callback: function ($$v) {
                _vm.termsApproved = $$v
              },
              expression: "termsApproved",
            },
          }),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "490px", scrollable: "" },
              model: {
                value: _vm.tcDialog,
                callback: function ($$v) {
                  _vm.tcDialog = $$v
                },
                expression: "tcDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "h3",
                      { staticClass: "text-uppercase mx-auto mt-0 mb-0" },
                      [_vm._v(_vm._s(_vm.$t("TermsAndConditions")))]
                    ),
                    _c("i", {
                      staticClass: "mdi mdi-close-circle-outline",
                      on: {
                        click: function ($event) {
                          return _vm.openCloseTCDialog(false)
                        },
                      },
                    }),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0 pb-0" },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "justify" } },
                                [
                                  _c("p", {
                                    staticClass:
                                      "font-family-rubik U-blue-text",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.termsAndCondition),
                                    },
                                  }),
                                ]
                              ),
                              _c("v-spacer"),
                              _c(
                                "div",
                                { attrs: { "m-3": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        elevation: "0",
                                        color:
                                          "u-background-primary white--text text-none",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doAgree(true)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("IAgree")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none",
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { elevation: "0" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doAgree(false)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("DontAgree")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-actions"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }